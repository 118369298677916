import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick"; // Import the Slider component
import "slick-carousel/slick/slick-theme.css"; // Import the slick-carouseld
import "slick-carousel/slick/slick.css"; // Import the slick-carousel CSS
import BeerGlasses from "../Assets/images/beer_glasses.png";
import ChikenPan from "../Assets/images/chicken_pan.png";
import ChilliPowder from "../Assets/images/chilli_powder.png";
import Coriender from "../Assets/images/coriender.png";
import Dlogo from '../Assets/images/DLogo.png';
import Fries from "../Assets/images/fries_2.png";
import RedChilli from "../Assets/images/red_chilli_img.png";
import Stigberets from "../Assets/images/stigberets_img.png";
import Tomato from "../Assets/images/tomato.png";
import { handleLinkClick } from "../CommonJquery/CommonJquery.js";
import '../Css/BookTableModal.css';
import "../Css/HomePageCss.css";
import {
  get_event_data,
  server_post_data,
} from "../ServiceConnection/serviceconnection.js";
import "../SrollIcons.js";
import BookTableModal from './BookTableModal';
import Footer from "./Footer";
import Header from "./Header";
const Burger = require("../Assets/images/burger_bg_green.png");

export default function HomePage() {
  AOS.init({ duration: 1800 });

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    className: "slider-container",
  };
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const [currentGroup, setCurrentGroup] = useState(1);
  const [event_details, setevent_details] = useState([]);
  const [cap_details, setcap_details] = useState([]);
  const [imagelink, setimagelink] = useState([]);
  const [imagecaplink, setimagecaplink] = useState([]);
  const [blogs, setblogs] = useState([]);
  const [SEOloop, setSEOloop] = useState([]);
  const [showLoader, setShowLoader] = useState(false);

  const handleFetchData = async () => {
    setShowLoader(true);
    var form_data = new FormData();
    await server_post_data(get_event_data, form_data)
      .then((Response) => {
        if (Response.data.error) {
          alert(Response.data.message);
        } else {
          setimagelink(Response.data.message.image_link);
          setevent_details(Response.data.message.event_data);
          setimagecaplink(Response.data.message.cap_link);
          setcap_details(Response.data.message.cap_data);
          setblogs(Response.data.message.blog_list);
          setSEOloop(Response.data.message.seo_loop);
        }
        setShowLoader(false);
      })
      .catch((error) => {
        setShowLoader(false);
      });
  };

  const match_and_return_seo_link = (v_id) => {
    let data_seo_link_final = "/blog/blog_detail/" + v_id;
    let data_seo_link = data_seo_link_final;
    if (SEOloop) {
      const matchedItem = SEOloop.find(
        (data) => data_seo_link === data.call_function_name
      );

      if (matchedItem) {
        data_seo_link_final = matchedItem.pretty_function_name;
      }
    }

    return data_seo_link_final;
  };
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentGroup((prevGroup) => (prevGroup % 4) + 1);
    }, 8000); // Total animation duration (8s each group) * 3
    handleFetchData();
    return () => clearInterval(interval);
  }, []);

  function getDivisionResult(numerator, denominator) {
    return Math.ceil(numerator / denominator);
  }

  return (
    <div className="home_page">
      <div className={showLoader ? "loading" : ""}></div>
      <Header />

      <section className="welcome_banner">
        {event_details.length > 0 ? (
          <Slider {...sliderSettings} className="m-0">
            <div className="slide-item">
              <div className="container-lg position-relative">
                <div className="row m-0">
                  <div className="col-lg-5 col-md-6 col-sm-7 col-sm-10 col-9 burger_div m-auto">
                    <div className="burger_div">
                      <div className="burger_bg">
                        <div className="ingredient_bg"></div>
                        <img
                          className="burger_img"
                          width="100%"
                          fetchPriority="high"
                          loading="lazy"
                          src={Burger}
                          alt="The Best Restaurant in Sweden, Barley's Food Factory, hand-crafted microbreweries, gourmet burger restaurants in Sweden, microbreweries, burger restaurant, Swedish cuisine"
                        />
                      </div>
                    </div>
                    <div className="vega_logo d-sm-none"></div>
                  </div>
                  <div className="col-md-5 col-sm-12 welcome_bg position-relative m-auto">
                    <div className="oo_img"></div>
                    <div className="food_factory_bg d-flex align-items-center m-auto">
                      <div className="food_factory_inner_div">
                        <div className="pink_bg"></div>
                        <h1 className="barleys_underline">
                          <div>Barley's</div>
                          Food Factory
                        </h1>
                      </div>
                    </div>
                    <img
                      className="stigberets_img"
                      fetchPriority="high"
                      loading="lazy"
                      src={Stigberets}
                      alt="The Best Restaurant in Sweden, Barley's Food Factory, hand-crafted microbreweries, gourmet burger restaurants in Sweden, microbreweries, burger restaurant, Swedish cuisine"
                    />
                    <div className="vega_logo d-none d-sm-block"></div>
                  </div>
                </div>
              </div>
            </div>
            {event_details.map((slide, index) => (
              <div key={index} className="slide-item">
                <div className="container position-relative">
                  <div className="row m-0">
                    <div className="col-lg-5 col-md-6 col-sm-7 col-sm-10 col-9 burger_div m-auto">
                      <div className="burger_div">
                        <div className="burger_bg1">
                          <img
                            className="burger_img"
                            fetchPriority="high"
                            loading="lazy"
                            width="100%"
                            src={imagelink + slide.event_image}
                            alt="The Best Restaurant in Sweden, Barley's Food Factory, hand-crafted microbreweries, gourmet burger restaurants in Sweden, microbreweries, burger restaurant, Swedish cuisine"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12 welcome_bg position-relative m-auto">
                      <div className="food_factory_bgevent d-flex align-items-center m-auto">
                        <div className="food_factory_inner_div">
                          <h1 className="barleys_event_name">
                            {slide.event_name}
                          </h1>
                          <div className="mt-3">
                            <h3>DATE: {slide.event_date}</h3>
                            <h3 style={{ whiteSpace: "nowrap" }}>
                              TIME: {slide.event_time} ONWARDS
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="event_details">
                        <p>{slide.event_des}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        ) : (
          <div className="container-lg position-relative">
            <div className="row m-0">
              <div className="col-lg-5 col-md-6 col-sm-7 col-sm-10 col-9 burger_div m-auto">
                <div className="burger_div">
                  <div className="burger_bg">
                    <div className="ingredient_bg"></div>
                    <img
                      className="burger_img"
                      fetchPriority="high"
                      loading="lazy"
                      width="100%"
                      src={Burger}
                      alt="The Best Restaurant in Sweden, Barley's Food Factory, hand-crafted microbreweries, gourmet burger restaurants in Sweden, microbreweries, burger restaurant, Swedish cuisine"
                    />
                  </div>
                </div>
                <div className="vega_logo d-sm-none"></div>
              </div>
              <div className="col-md-5 col-sm-12 welcome_bg position-relative m-auto">
                <div className="oo_img"></div>
                <div className="food_factory_bg d-flex align-items-center m-auto">
                  <div className="food_factory_inner_div">
                    <div className="pink_bg"></div>
                    <h1 className="barleys_underline">
                      <div>Barley's</div>
                      Food Factory
                    </h1>
                  </div>
                </div>
                <img
                  className="stigberets_img"
                  fetchPriority="high"
                  loading="lazy"
                  src={Stigberets}
                  alt="The Best Restaurant in Sweden, Barley's Food Factory, hand-crafted microbreweries, gourmet burger restaurants in Sweden, microbreweries, burger restaurant, Swedish cuisine"
                />
                <div className="vega_logo d-none d-sm-block"></div>
              </div>
            </div>
          </div>
        )}
      </section>

      <section className="beer_logo_caps mt-4 mx-auto">
        <div className="beer_logo_container">
          {" "}
          <div className={`image_group group${currentGroup}`}>
            {cap_details.map(
              (data_set, index) =>
                getDivisionResult(index + 1, 6) === currentGroup && (
                  <div className="logoImg_wrapper col-2 m-auto" key={index}>
                    <img
                      src={imagecaplink + data_set.cap_image}
                      alt={`beer logo`}
                    />
                    <p className="hideandshow image-heading">
                      {data_set.cap_name}
                    </p>
                  </div>
                )
            )}
          </div>
        </div>
      </section>

      <section className="content_section">
        <div className="content_wrapper">
          <div className="position-relative content_div_1">
            <div className="container-fluid position-relative p-0">
              <div className="content_text_1 col-md-7 col-sm-10 col-12">
                <p>
                  Welcome to Barley's Food Factory, an American Fun dining
                  restaurant that combines gourmet food with handcrafted
                  microbrewery beers from Sweden and around the world. In 2018,
                  Barley's Food Factory was honored as one of the best gourmet
                  burger restaurants in Sweden, and we have continued to live up
                  to that recognition ever since.
                </p>
              </div>
              <div className="akia_logo"></div>
              <div className="poppels_2_logo"></div>
              <div className="b_logo"></div>
              <div className="fermen_logo"></div>
              <div className="gotlands_logo"></div>
              <div className="stockholm_logo"></div>
            </div>
          </div>

          <div className="content_div_2">
            <div className="container-fluid position-relative content_div_2_container">
              <div className="row m-0 beer_div">
                <div className="col-lg-5 col-md-7 col-sm-8 text-center champagne_bg m-auto">
                  <img
                    className="beer_img"
                    fetchPriority="high"
                    loading="lazy"
                    width="100%"
                    src={BeerGlasses}
                    alt="The Best Restaurant in Sweden, Barley's Food Factory, hand-crafted microbreweries, gourmet burger restaurants in Sweden, microbreweries, burger restaurant, Swedish cuisine"
                  />
                  {/* <img className='peanut_3' src={Peanut3} alalt='Barley-Food' ata-aos="fade-right" /> */}
                  {/* <img className='smallPeanut2' src={smallPeanut2} alt='Barley-Food' /> */}
                  <div className=""></div>
                  <div className="omni d-sm-none"></div>
                </div>
                <div className="col-lg-6 col-xs-11 position-relative d-flex align-items-center p-0">
                  <div className="content_text_2">
                    <p>
                      At Barley's Food Factory, we believe in constantly
                      refreshing our beverage menu, with a collection of over
                      100 beers from local microbreweries and international
                      brands, both in cans and on draft.
                    </p>
                  </div>
                </div>
              </div>
              <div className="logos_div position-relative">
                <div className="beerbliotek"></div>
                <div className="joker"></div>
                <div className="billdale"></div>
                <div className="brutal"></div>
                <div className="billdale"></div>
              </div>
            </div>
          </div>

          <div className="content_div_3">
            <div className="container-fluid">
              <div className="row m-0 nonveg_div">
                <div className="nonveg_bg col-md-5 col-sm-7 col-9 m-sm-auto position-relative">
                  <img
                    className="nonveg_img"
                    fetchPriority="low"
                    loading="lazy"
                    src={ChikenPan}
                    data-aos="fade-right"
                    alt="The Best Restaurant in Sweden, Barley's Food Factory, hand-crafted microbreweries, gourmet burger restaurants in Sweden, microbreweries, burger restaurant, Swedish cuisine"
                  />
                  <img
                    className="red_chilli"
                    fetchPriority="low"
                    loading="lazy"
                    src={RedChilli}
                    data-aos="zoom-out"
                    alt="The Best Restaurant in Sweden, Barley's Food Factory, hand-crafted microbreweries, gourmet burger restaurants in Sweden, microbreweries, burger restaurant, Swedish cuisine"
                  />
                  <img
                    className="tomato"
                    fetchPriority="low"
                    loading="lazy"
                    src={Tomato}
                    data-aos="fade-down"
                    alt="The Best Restaurant in Sweden, Barley's Food Factory, hand-crafted microbreweries, gourmet burger restaurants in Sweden, microbreweries, burger restaurant, Swedish cuisine"
                  />
                  <img
                    className="chilli_powder"
                    fetchPriority="low"
                    loading="lazy"
                    src={ChilliPowder}
                    data-aos="zoom-out"
                    alt="The Best Restaurant in Sweden, Barley's Food Factory, hand-crafted microbreweries, gourmet burger restaurants in Sweden, microbreweries, burger restaurant, Swedish cuisine"
                  />
                  <img
                    className="coriender"
                    src={Coriender}
                    fetchPriority="low"
                    loading="lazy"
                    alt="The Best Restaurant in Sweden, Barley's Food Factory, hand-crafted microbreweries, gourmet burger restaurants in Sweden, microbreweries, burger restaurant, Swedish cuisine"
                  />
                  <div className="good_guy1 d-sm-none"></div>
                  <div className="spike1 d-sm-none"></div>
                </div>
                <div className="content_text_3 col-lg-5 col-md-10 position-relative p-0">
                  <div className="good_guy d-none d-sm-block"></div>
                  <div className="spike d-none d-sm-block"></div>
                  <p>
                    Our food menu showcases a diverse range of dishes inspired
                    by North and South America, infused with international
                    twists. We take pride in offering numerous options for
                    vegans and vegetarians.
                  </p>
                  <div className="pp"></div>
                  <div className="old_island"></div>
                </div>
              </div>
            </div>
          </div>

          <div className="content_div_4">
            <div className="container-fluid position-relative">
              <div className="row m-0">
                <div className="content_text_4 col-md-4 m-auto position-relative d-none d-sm-block">
                  <p>BOOK A TABLE</p>
                </div>
                <div className="col-md-7 m-auto d-flex justify-content-center position-relative">
                  <img
                    className="fries_img"
                    fetchPriority="low"
                    loading="lazy"
                    src={Fries}
                    alt="The Best Restaurant in Sweden, Barley's Food Factory, hand-crafted microbreweries, gourmet burger restaurants in Sweden, microbreweries, burger restaurant, Swedish cuisine"
                  />
                  <div className="fry_stick_1"></div>
                  <div className="fry_stick_2"></div>
                  <div className="fry_stick_3"></div>
                  <div className="fry_stick_4"></div>
                </div>
              </div>
              <div className="dugges"></div>
              <div className="ten_hands"></div>
              <div className="goteburgs"></div>
            </div>
          </div>
        </div>
      </section>

      <section className="barleysBLog container-lg">
        <div className="barleysBLogContainer">
          <h2 className="text-center mb-5">Our Blogs</h2>
          <div className="barleysBLogWrapper">
            <div className="row mx-0 barleysBLogCardscontainer">
              {blogs.map((data, index) => (
                <div
                  key={index}
                  className="col-lg-3 col-sm-4 mb-5"
                // data-aos="fade-up"
                >
                  <Link
                    onClick={() =>
                      handleLinkClick(
                        match_and_return_seo_link(data.primary_id)
                      )
                    }
                  >
                    <div className="barleysBLogcard">
                      <div className="barleysBLogcardHead">
                        <img
                          src={data.image_url}
                          fetchPriority="low"
                          loading="lazy"
                          alt="The Best Restaurant in Sweden, Barley's Food Factory, hand-crafted microbreweries, gourmet burger restaurants in Sweden, microbreweries, burger restaurant, Swedish cuisine"
                        />
                      </div>

                      <div className="barleysBLogcardbody">
                        <h5>{data.title_name}</h5>
                        <h6>{data.tag_line}</h6>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
              <div id="footer_icons_hide"></div>
              <div id="hide_bottom_btn"></div>
            </div>
          </div>

          <div className="forMoreBtn">
            <Link onClick={() => handleLinkClick("/blog")}>
              <button type="button">More Blogs</button>
            </Link>
          </div>
        </div>
      </section>
      <button className="book-table-button" onClick={openModal}>
        <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
          <img
            style={{ height: "25px", width: "25px" }}
            src={Dlogo}
            alt="Image Not found" />
            <div style={{ width: '1px', height: '30px', margin:'0 1rem', backgroundColor: '#FFFFFF' }} />
          <p style={{margin:"0.5rem 0",alignContent:'center' ,textAlign:'center'}}>Book a Table</p>
        </div>
      </button>

      {/* Booking Modal */}
      <BookTableModal isOpen={isModalOpen} onClose={closeModal} />
      {/* <div
        className="bottom_nav_btn container-fluid d-block d-sm-none my-3 d-flex justify-content-center"
        id="bottom_btn"
      >
        <button className="bottom_btn shadow">
          <Link onClick={() => handleLinkClick("/booking")}>
            BOOK A TABLE
          </Link>
        </button>
      </div> */}
      <Footer />
    </div>
  );
}
