import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import alarmon from "../Assets/BookaTableAsset/Images/alarm_on.png";
import DiscountImg from "../Assets/BookaTableAsset/Images/backdiscountImg.jpg";
import Calendar from "../Assets/BookaTableAsset/Images/calendar_month.png";
import folder from "../Assets/BookaTableAsset/Images/folder_managed.png";
import group from "../Assets/BookaTableAsset/Images/group.png";
import gif_save from "../Assets/images/9e0a9916ea4e310d664ee68a3de78adf.gif";
import closeBtn from "../Assets/images/CloseBtn.png";
// import events from "../Assets/BookaTableAsset/Images/events.png";
import { formatTimeintotwodigit } from "../CommonJquery/CommonJquery.js";
import "../Css/BookTableModal.css";
import "../Css/CalenderBook.css";
import {
  create_table_reservation_website,
  get_all_timing_date_wise,
  get_event_data,
  server_post_data,
} from "../ServiceConnection/serviceconnection.js";
const BookTableModal = ({ isOpen, onClose }) => {
  let one_by_call = 0;
  let child_length = 2;
  let pet_length = 2;
  let guest_length = 2;
  const [calendarHook, setCalendarHook] = useState(false);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [timesHook, settimesHook] = useState(false);
  const [guestHook, setguestHook] = useState(false);
  const [formsHook, setformsHook] = useState(false);
  const [errormsg, seterrormsg] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedDate, setSelectedDate] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [TimeData, setTimeData] = useState([]);
  const [showModalKitchenMsg, setShowModalKitchenMsg] = useState("");
  const [phoneno, setPhoneno] = useState("4631515748"); //default phonenumber for testing
  const [data, setData] = useState([]);
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedTimeDiscount, setSelectedTimeDiscount] = useState(0);
  const [selectedGuest, setSelectedGuest] = useState(1);
  const [errorform, seterrorform] = useState({ error: false });
  const [selectedrtsd_idd, setSelectedrtsd_idd] = useState(null);
  const [showModalKitchen, setShowModalKitchen] = useState(false);
  const [showAddbutton, setshowAddbutton] = useState(true);
  const [showAddbutton2, setshowAddbutton2] = useState(true);
  const [addCustomChild, setAddCustomChild] = useState(false);
  const [showAddbutton3, setshowAddbutton3] = useState(true);
  const [addCustomGuest, setAddCustomGuest] = useState(false);
  const [selectedChild, setselectedChild] = useState(0);
  const [selectedpet, setselectedpet] = useState(0);
  const [addCustomPet, setAddCustomPet] = useState(false);
  const [ShowHideData, setShowHideData] = useState(false);
  const [event_details, setevent_details] = useState([]);
  const [imagelink, setimagelink] = useState([]);
  //guest details hooks
  const [guestName, setGuestName] = useState("");
  const [guestNumber, setGuestNumber] = useState("");
  const [guestEmail, setGuestEmail] = useState("");
  const [guestMessage, setGuestMessage] = useState("");
  const [totalbookingtoday, settotalbookingtoday] = useState(0);

  const SelectedChangeChild = (child_name, click_type) => {
    setshowAddbutton(true);
    if (click_type === "1") {
      setselectedChild(child_name);
      setAddCustomChild(false);
      setshowAddbutton2(true);
    } else {
      child_name.target.value = child_name.target.value.replace(/[^0-9]/g, "");
      if (child_name.target.value === "") {
        child_name.target.value = 1;
      } else if (Number(child_name.target.value) < 1) {
        child_name.target.value = 1;
      }
      setselectedChild(child_name.target.value);
      setshowAddbutton2(true);
    }
  };

  console.log("event_details", event_details);

  const SelectedChangePet = (pet_name, click_type) => {
    setshowAddbutton(true);
    if (click_type === "1") {
      setshowAddbutton3(true);
      setselectedpet(pet_name);
      setAddCustomPet(false);
    } else {
      pet_name.target.value = pet_name.target.value.replace(/[^0-9]/g, "");
      if (pet_name.target.value === "") {
        pet_name.target.value = 1;
      } else if (Number(pet_name.target.value) < 1) {
        pet_name.target.value = 1;
      }
      setselectedpet(pet_name.target.value);
      setshowAddbutton3(true);
    }
  };
  useEffect(() => {
    let today = new Date(),
      date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
    setSelectedDate(date);
    handleFetchData(date);
    handleFetchDataevent();
  }, [selectedDate === null]);
  const handleFetchData = async (date_for_book) => {
    if (one_by_call === 0) {
      one_by_call = 1;
      setShowLoader(true);
      setData(null);
      var form_data = new FormData();

      form_data.append("special_date", date_for_book);
      // form_data.append("special_date", "2024-02-26");
      form_data.append("reservation_id", "0");
      console.log(get_all_timing_date_wise)
      await server_post_data(get_all_timing_date_wise, form_data)
        .then((Response) => {
          console.log(Response)
          one_by_call = 0;
          setTimeData(Response.data.message.data_timedata);
          setShowModalKitchenMsg(Response.data.message.kichan_lose_msg);
          setShowLoader(false);
          settotalbookingtoday(Response.data.message.total_booking_count);
          if (Response.data.message.footer_data.length > 0) {
            setPhoneno(Response.data.message.footer_data[0].phone_no);
          }
          let online_booking_status = 0;
          let start_stop_status = 0;
          if (Response.data.message.get_date_off_date.length > 0) {
            online_booking_status =
              Response.data.message.get_date_off_date[0].online_booking_status;
            start_stop_status =
              Response.data.message.get_date_off_date[0].start_stop_status;
          } else {
            if (Response.data.message.get_date_off_on_day.length > 0) {
              online_booking_status =
                Response.data.message.get_date_off_on_day[0]
                  .online_booking_status;
              start_stop_status =
                Response.data.message.get_date_off_on_day[0].start_stop_status;
            }
          }
          if (
            Response.data.message.data_timedatadetails.length > 0 &&
            online_booking_status === 0 &&
            start_stop_status === 0
          ) {
            setData(Response.data.message.data_timedatadetails);
            console.log(Response.data.message.data_timedatadetails)
            if (Response.data.message.data_timedatadetails.length > 0) {
              setSelectedTime(
                Response.data.message.data_timedatadetails[0].start_time
              );
              setSelectedrtsd_idd(
                Response.data.message.data_timedatadetails[0].primary_id
              );
            }
            seterrorform({ error: true });
          } else {
            seterrorform({ error: false });
          }
          setSelectedGuest(1); // Set the selected guest count
        })
        .catch((error) => {
          setShowLoader(false);
          one_by_call = 0;
        });
    }
  };

  const handleFetchDataevent = async () => {
    setShowLoader(true);
    var form_data = new FormData();
    await server_post_data(get_event_data, form_data)
      .then((Response) => {
        if (Response.data.error) {
          alert(Response.data.message);
        } else {
          setimagelink(Response.data.message.image_link);
          setevent_details(Response.data.message.event_data);
        }
        setShowLoader(false);
      })
      .catch((error) => {
        setShowLoader(false);
      });
  };

  // useEffect(() => {
  //   handleFetchData(selectedDate);
  // }, []);

  const sava_booking_data = async () => {
    if (isValid) {
      setShowLoader(true);

      var fd_from = new FormData();
      fd_from.append("reservation_id", "0");
      fd_from.append("guest_mobile_no", guestNumber);
      fd_from.append("book_date", selectedDate);
      fd_from.append("operational_time_detail_id", selectedrtsd_idd);
      fd_from.append("operational_booking_time", selectedTime);
      fd_from.append("booking_type", "2");
      fd_from.append("realtime", "0");
      if (selectedChild === "") {
        fd_from.append("no_of_child", 0);
      } else {
        fd_from.append("no_of_child", selectedChild);
      }
      if (selectedpet === "") {
        fd_from.append("no_of_pets", 0);
      } else {
        fd_from.append("no_of_pets", selectedpet);
      }
      fd_from.append("no_of_guest", selectedGuest);

      fd_from.append("total_tablebooking", "0");
      fd_from.append("dining_area_id", "0");
      fd_from.append("reservation_comment", guestMessage);
      fd_from.append("guest_name", guestName);
      fd_from.append("guest_email", guestEmail);

      await server_post_data(create_table_reservation_website, fd_from)
        .then((Response) => {
          setShowLoader(false);
          if (Response.data.error) {
            alert(Response.data.message);
          } else {
            // $("#namecall").val("");
            // $("#emailcall").val("");
            // // setPhone("");
            // $("#order_comment").val("");
            // handleclickbackstep(0, "");
            // setModalMessage(Response.data.message);
            // handleShow();
            setShowHideData(true);
          }
        })
        .catch((error) => {
          console.log(error);
          setShowLoader(false);
        });
    }
  };

  const [isValid, setIsValid] = useState(true);

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setGuestEmail(email);

    // Validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValid(email === "" || emailRegex.test(email)); // Allow empty or valid email
  };

  const handleclickstep = (step_click, data_for_update) => {
    if (step_click === 0) {
      setSelectedDate(null);
      setSelectedTime(null);
      setSelectedGuest(1);
      setCurrentStep(1);
    } else if (step_click === 1) {
      let month = data_for_update.$M + 1;
      let day = data_for_update.$D;
      let year = data_for_update.$y;

      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }
      let full_date = year + "-" + month + "-" + day;
      setSelectedDate(full_date);

      handleFetchData(full_date);
    } else if (step_click === 2) {
      let make_data = data_for_update.split("~@~");
      setSelectedrtsd_idd(make_data[0]);
      setSelectedTime(make_data[1]);
      setSelectedTimeDiscount(make_data[2]);
      setSelectedGuest(1); // Set the selected guest count
      settimesHook(false);

      let click_by_popup = 0;
      TimeData.map((item) => {
        if (make_data[1] >= item.last_arrival_time) {
          click_by_popup = 1;
          setShowModalKitchen(true);
        }
      });
      if (click_by_popup === 0) {
        setCurrentStep(3);
      }
    } else if (step_click === 3) {
      setSelectedGuest(data_for_update); // Set the selected guest count
      setCurrentStep(4);
    } else if (step_click === 4) {
    }
  };

  const handleDateSelect = (date) => {
    console.log("date", date);
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    console.log("formattedDate", formattedDate);
    handleFetchData(formattedDate);
    setSelectedDate(formattedDate);
    setIsCalendarOpen(false);
  };

  // Toggle calendar visibility
  const toggleCalendar = () => {
    setIsCalendarOpen(!isCalendarOpen);
    settimesHook(false);
    setguestHook(false);
    setformsHook(false);
  };

  const addCustomChildInput = () => {
    setAddCustomChild(true);
    setselectedChild("");
    setshowAddbutton2(false);
  };
  const SelectedChangeGuest = (pet_name, click_type) => {
    setshowAddbutton(true);
    if (click_type === "1") {
      setSelectedGuest(pet_name);
      setAddCustomGuest(false);
      setshowAddbutton(true);
    } else {
      pet_name.target.value = pet_name.target.value.replace(/[^0-9]/g, "");
      if (pet_name.target.value === "") {
        pet_name.target.value = 1;
      } else if (Number(pet_name.target.value) < 1) {
        pet_name.target.value = 1;
      }
      setSelectedGuest(pet_name.target.value);
      setshowAddbutton(true);
    }
  };

  const handleclikModels = (click_type) => {
    if (click_type === 1) {
      setCalendarHook(!calendarHook);
      settimesHook(false);
      setguestHook(false);
      setformsHook(false);
    }
    if (click_type === 2) {
      setIsCalendarOpen(false);
      settimesHook(!timesHook);
      setguestHook(false);
      setformsHook(false);
    }
    if (click_type === 3) {
      setIsCalendarOpen(false);
      settimesHook(false);
      setguestHook(!guestHook);
      setformsHook(false);
    }
    if (click_type === 4) {
      setIsCalendarOpen(false);
      settimesHook(false);
      setguestHook(false);
      setformsHook(!formsHook);
    }

    // Convert per_discount from string to integer
  };
  let maxDiscountData;
  if (data && data.length > 0) {
    // Convert per_discount to integers and find the maximum discount
    const maxDiscount = Math.max(
      ...data.map((item) => parseInt(item.per_discount, 10))
    );
    // Filter data to get only items with the maximum discount
    if (maxDiscount === 0) {
      maxDiscountData = [];
    } else {
      // Filter data to get only items with the maximum discount
      maxDiscountData = data.filter(
        (item) => parseInt(item.per_discount, 10) === maxDiscount
      );
    }
  } else {
    maxDiscountData = [];
  }

  // Now, log the result

  console.log(maxDiscountData);
  console.log(ShowHideData)
  // const formattedTime = new Date('1970-01-01T' + selectedTime + 'Z').toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
  return (
    <>
      <div className={showLoader ? "loading" : ""}></div>
      {/* Overlay */}
      {isOpen && <div className="modal-overlay111" onClick={onClose}></div>}

      {/* Modal Content */}
      {isOpen && (
        <div className={`modal-content111 ${isOpen ? "show" : ""}`}>
          <img
            src={closeBtn}
            className="close-button"
            onClick={onClose}
            alt="Close"
          />
          {ShowHideData ? (
            <h2 className="last-page-7666">Thank You</h2>
          ) : (
            <div className="bookTableTop">
              <h2>Book a Table</h2>
            </div>
          )}
          <div>
            <p className="PhoneNumber">Call us: +4631515748</p>
          </div>
          {!ShowHideData? (
            <div className="align_data"  style={{ width:"100%", }}>
              <div className="showing-the-data w-100 py-2" style={{ overflowY: "scroll" ,overflowX:"hidden"}}>
                <div className="calendar-container">
                  <div className="dateget" onClick={toggleCalendar}>
                    <div className="img-div-calender">
                      <div className="setup">
                        <div className="square">
                          <img src={Calendar} alt="Calendar" />
                          <p>Date</p>
                        </div>
                        <div className="triangle-right"></div>
                      </div>
                      <div className="selected-date">
                        {selectedDate ? selectedDate : "Select Date"}
                      </div>
                    </div>
                  </div>
                  {/* Calendar Modal */}
                  {isCalendarOpen && (
                    <div className="calendar-modal">
                      {/* Calendar Header */}
                      <div className="calendar-header">
                        <h3>Find a Table</h3>
                        <p>Book for Free</p>
                      </div>

                      <div className="bookingcont">
                        {totalbookingtoday > 0 && (
                          <div className="booking-banner">
                            🔥 Already {totalbookingtoday} bookings today
                          </div>
                        )}
                      </div>

                      {/* Calendar Component */}
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <div className="calendar-container">
                          <DateCalendar
                            minDate={dayjs()} // Restrict past dates
                            onChange={handleDateSelect}
                            sx={{
                              // Calendar header styles
                              ".MuiPickersCalendarHeader-root": {
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                padding: "0px",
                              },
                              ".MuiPickersCalendarHeader-labelContainer": {
                                position: "absolute",
                                left: "50%",
                                transform: "translateX(-50%)",
                                fontWeight: "0",
                                color: "#2A2D32",
                                fontSize: "12px",
                              },
                              ".MuiPickersCalendarHeader-switchViewButton": {
                                display: "none", // Hides the year button
                              },
                              ".MuiPickersArrowSwitcher-root": {
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                              },
                              ".MuiPickersArrowSwitcher-button": {
                                backgroundColor: "transparent",
                                border: "none",
                                fontSize: "18px",
                                fontWeight: "bold",
                                color: "#333",
                                padding: "0 12px",
                                cursor: "pointer",
                              },

                              // Calendar day styles
                              ".MuiDayCalendar-weekContainer": {
                                marginTop: "0px",
                              },
                              ".MuiPickersDay-root": {
                                color: "#333",
                                fontWeight: "500",
                                fontSize: "12px",
                              },
                              ".Mui-selected": {
                                backgroundColor: "#535969",
                                color: "#fff",
                                borderRadius: "50%",
                              },
                              ".MuiPickersDay-today": {
                                border: "1px solid #374151",
                                color: "#374151",
                              },
                            }}
                          />
                        </div>
                      </LocalizationProvider>
                    </div>
                  )}
                </div>
                <div className="dateget" onClick={() => handleclikModels(2)}>
                  <div className="img-div-calender">
                    <div className="setup">
                      <div className="square">
                        <img src={alarmon} alt="alarmon" />
                        <p>Time</p>
                      </div>
                      <div className="triangle-right"></div>
                    </div>
                    <div className="selectedtimetext">
                      <p>
                        {/* {formattedTime === "Invalid Date" ? "" : formattedTime}{" "} */}
                        {selectedTime}
                        {parseInt(selectedTimeDiscount) !== 0 && (
                          <span className="discount">
                            ({selectedTimeDiscount}% Discount)
                          </span>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                {timesHook && (
                  <>
                    <div className="time-modal">
                      <div className="calendar-header">
                        <h3>Find a Table</h3>
                        <p>Book for free</p>
                      </div>

                      <div className="bookingcont">
                        {totalbookingtoday > 0 && (
                          <div className="booking-banner">
                            🔥 Already {totalbookingtoday} bookings today
                          </div>
                        )}
                      </div>
                      <div className="BookingTimeText">
                        <p>Booking Time</p>
                      </div>
                      <span className="venuePage_venue_capacity_wrapper">
                        <div className="time-selection-scrollable">
                          <div className="time_discount_container">
                            {errorform.error == true && data !== null ? (
                              data.slice(0, -1).map((item, index) => {
                                if (item.start_stop_time_status === 0) {
                                  if (item.online_booking_time_status === 0) {
                                    return (
                                      <div
                                        key={index}
                                        className="time_discount_sectione332 available"
                                        onClick={() =>
                                          handleclickstep(
                                            2,
                                            item.primary_id +
                                            "~@~" +
                                            item.start_time +
                                            "~@~" +
                                            item.per_discount
                                          )
                                        }
                                      >
                                        <div className="time_section244">
                                          <p>
                                            {formatTimeintotwodigit(
                                              item.start_time
                                            )}
                                          </p>
                                        </div>
                                        {item.per_discount > 0 ? (
                                          <div className="discount_section2333">
                                            <p>-{item.per_discount}%</p>
                                          </div>
                                        ) : (
                                          <div className="discount_section2333">
                                            <p>N/A</p>
                                          </div>
                                        )}
                                      </div>
                                    );
                                  } else {
                                    return (
                                      <div
                                        key={index}
                                        className="time_discount_sectione332 block_table"
                                      >
                                        <div className="time_section">
                                          <p>
                                            {formatTimeintotwodigit(
                                              item.start_time
                                            )}
                                          </p>
                                        </div>
                                        {item.per_discount > 0 ? (
                                          <div className="discount_section2333">
                                            <p>-{item.per_discount}%</p>
                                          </div>
                                        ) : (
                                          <div className="discount_section2333">
                                            <p>N/A</p>
                                          </div>
                                        )}
                                      </div>
                                    );
                                  }
                                }
                              })
                            ) : (
                              <div className="time_discount_sectione332 padding_no_show_msg333">
                                {errormsg}
                              </div>
                            )}
                          </div>
                        </div>
                      </span>
                    </div>
                  </>
                )}
                <div className="dateget" onClick={() => handleclikModels(3)}>
                  <div className="img-div-calender">
                    <div className="setup">
                      <div className="square">
                        <img src={group} alt="group" />
                        <p>Guest</p>
                      </div>
                      <div className="triangle-right"></div>
                    </div>
                    <div className="selectedGuestText">
                      <p>
                        {selectedGuest === null ? 0 : selectedGuest} Per. I{" "}
                        {selectedChild} Child I {selectedpet} Pet
                      </p>
                    </div>
                  </div>
                </div>
                {guestHook && (
                  <>
                    <div className="time-modal">
                      <div className="calendar-header">
                        <h3>Find a Table</h3>
                        <p>Book for free</p>
                      </div>

                      <div className="bookingcont">
                        {totalbookingtoday > 0 && (
                          <div className="booking-banner">
                            🔥 Already {totalbookingtoday} bookings today
                          </div>
                        )}
                      </div>
                      <div className="wrapper_calendar_modal11111111111">
                        <h6 className="calendar_modal_heading4324">
                          Number of Guests
                        </h6>
                        <div className="resrvDateSelect">
                          <ul>
                            {/* {Array.from(
                              { length: guest_length },
                              (_, index) => index + 1
                            ).map((digit, index) => (
                              <li key={index}>
                                <div
                                  className={`dateBox ${selectedGuest === index + 1
                                    ? "selectedFormItems"
                                    : ""
                                    }`}
                                  onClick={() =>
                                    SelectedChangeGuest(index + 1, "1")
                                  }
                                >
                                  <p>{index + 1}</p>
                                </div>
                              </li>
                            ))} */}
                            <li>
                              <div
                                className={`selectedFormItems ${selectedGuest === 0 ? "disabled" : ""
                                  }`}
                                onClick={() => {
                                  if (selectedGuest > 1) {
                                    SelectedChangeGuest(selectedGuest - 1, "1");
                                  }
                                }}
                              >
                                <p>-</p>
                              </div>
                            </li>
                            <li>
                              <div
                                className={""}
                              // onClick={() =>
                              //   SelectedChangeGuest(selectedGuest + 1, "1")
                              // }
                              >
                                <p>{selectedGuest}</p>
                              </div>
                            </li>

                            <li>
                              <div
                                className={"selectedFormItems"}
                                onClick={() =>
                                  SelectedChangeGuest(selectedGuest + 1, "1")
                                }
                              >
                                <p>+</p>
                              </div>
                            </li>
                            {/* <li
                          className={`${addCustomGuest ? " " : "hideInput"
                            }`}
                        >
                          <div className="customRsrvInput">
                            <input
                              type="text"
                              maxLength={3}
                              name="custom_pet_count"
                              placeholder="No. of Guests"
                              onChange={(e) =>
                                SelectedChangeGuest(e, "2")
                              }
                              className="addInputBtn"
                            />
                          </div>
                        </li> */}
                            {/* {showAddbutton && !addCustomGuest && (
                          <li>
                            <div
                            
                              className={` dateBox addInputBtn ${addCustomGuest ? "hideInput" : ""
                                }`}
                              onClick={addCustomGuestInput}
                            >
                              
                            </div>
                          </li>
                        )} */}
                          </ul>
                        </div>
                        {/* ===== */}
                        <h6 className="calendar_modal_heading4324">
                          Number of Children
                        </h6>

                        <div className="resrvDateSelect">
                          <ul>
                            {/* {Array.from(
                              { length: child_length },
                              (_, index) => index + 1
                            ).map((digit, index) => (
                              <li key={index}>
                                <div
                                  className={`dateBox ${selectedChild === index
                                    ? "selectedFormItems"
                                    : ""
                                    }`}
                                  onClick={() =>
                                    SelectedChangeChild(index, "1")
                                  }
                                >
                                  <p>{index}</p>
                                </div>
                              </li>
                            ))} */}
                            <li>
                              <div
                                className={`selectedFormItems ${selectedChild === 0 ? "disabled" : ""
                                  }`}
                                onClick={() => {
                                  if (selectedChild > 0) {
                                    SelectedChangeChild(selectedChild - 1, "1");
                                  }
                                }}
                              >
                                <p>-</p>
                              </div>
                            </li>
                            <li>
                              <div
                                className={""}
                              // onClick={() =>
                              //   SelectedChangeGuest(selectedGuest + 1, "1")
                              // }
                              >
                                <p>{selectedChild}</p>
                              </div>
                            </li>

                            <li>
                              <div
                                className={"selectedFormItems"}
                                onClick={() =>
                                  SelectedChangeChild(selectedChild + 1, "1")
                                }
                              >
                                <p>+</p>
                              </div>
                            </li>
                            {/* <li
                          className={`${addCustomChild ? " " : "hideInput"
                            }`}
                        >
                          <div className="customRsrvInput">
                            <input
                              type="text"
                              maxLength={3}
                              name="custom_child_count"
                              onBlur={(e) =>
                                SelectedChangeChild(e, "2")
                              }
                              placeholder="No. of Child"
                              className="addInputBtn"
                            />
                          </div>
                        </li> */}
                            {/* {showAddbutton2 && !addCustomChild && (
                          <li>
                            <div
                              className={`dateBox addInputBtn ${addCustomChild ? "hideInput" : ""
                                }`}
                              onClick={addCustomChildInput}
                            >
                             
                            </div>
                          </li>
                        )} */}
                          </ul>
                        </div>

                        <h6 className="calendar_modal_heading4324">
                          Number of Pets
                        </h6>
                        {/* <input type="phone" placeholder="Enter no of pets.." /> */}
                        <div className="resrvDateSelect">
                          <ul>
                            {/* {Array.from(
                              { length: pet_length },
                              (_, index) => index + 1
                            ).map((digit, index) => (
                              <li key={index}>
                                <div
                                  className={`dateBox ${selectedpet === index
                                    ? "selectedFormItems"
                                    : ""
                                    }`}
                                  onClick={() =>
                                    SelectedChangePet(index, "1")
                                  }
                                >
                                  <p>{index}</p>
                                </div>
                              </li>
                            ))} */}
                            <li>
                              <div
                                className={`selectedFormItems ${selectedpet === 0 ? "disabled" : ""
                                  }`}
                                onClick={() => {
                                  if (selectedpet > 0) {
                                    SelectedChangePet(selectedpet - 1, "1");
                                  }
                                }}
                              >
                                <p>-</p>
                              </div>
                            </li>
                            <li>
                              <div
                                className={""}
                              // onClick={() =>
                              //   SelectedChangeGuest(selectedGuest + 1, "1")
                              // }
                              >
                                <p>{selectedpet}</p>
                              </div>
                            </li>

                            <li>
                              <div
                                className={"selectedFormItems"}
                                onClick={() =>
                                  SelectedChangePet(selectedpet + 1, "1")
                                }
                              >
                                <p>+</p>
                              </div>
                            </li>
                            {/* <li
                        // className={`${addCustomPet ? " " : "hideInput"
                        //   }`}
                        >
                          <div className="customRsrvInput">
                            <input
                              type="text"
                              maxLength={3}
                              name="custom_pet_count"
                              // onChange={(e) =>
                              //   SelectedChangePet(e, "2")
                              // }
                              placeholder="No. of Pets"
                              className="addInputBtn"
                            />
                          </div>
                        </li> */}
                            {/* {showAddbutton3 && !addCustomPet && (
                          <li className="h-100">
                            <div
                              className={`addInputBtn dateBox ${addCustomPet ? "hideInput" : ""
                                }`}
                              onClick={addCustomPetInput}
                            >
                              <img
                                alt="Barley's Dashboard"
                              />
                            </div>
                          </li>
                        )} */}
                          </ul>
                        </div>

                        {/* <span>
                    <Link
                      onClick={() =>
                        handleclickstep(3, selectedGuest)
                      }
                    >
                      Next
                    </Link>
                  </span> */}
                      </div>
                    </div>
                  </>
                )}
                <div className="dateget" onClick={() => handleclikModels(4)}>
                  <div className="img-div-calender">
                    <div className="setup">
                      <div className="detailsquare">
                        <img src={folder} alt="folder" />
                        <p>Details</p>
                      </div>
                      <div className="detailtriangle-right"></div>
                    </div>
                    <div className="AddDetailText">
                      <p>Add Details</p>
                    </div>
                  </div>
                </div>

                {formsHook && (
                  <div className="form_box1111">
                    <div className="form_input1123">
                      {/* Name Field */}
                      <div className="input-container">
                        <input
                          type="text"
                          name="namecall"
                          id="namecall"
                          className="input-txt11111"
                          placeholder="User Name"
                          value={guestName}
                          onChange={(e) => {
                            const value = e.target.value.replace(
                              /[^A-Za-z\s]/g,
                              ""
                            ); // Allow only letters and spaces
                            setGuestName(value);
                          }}
                        />
                        <label htmlFor="namecall" className="floating-label">
                          User Name*
                        </label>
                      </div>

                      {/* Contact Number Field */}
                      <div className="input-container">
                        <input
                          type="text"
                          className="input-txt11111"
                          placeholder="Contact No."
                          maxLength={10}
                          value={guestNumber}
                          onChange={(e) => {
                            const value = e.target.value.replace(/[^0-9]/g, ""); // Allow only numbers
                            setGuestNumber(value);
                          }}
                        />
                        <label className="floating-label">Contact No.*</label>
                      </div>

                      {/* Email Field */}
                      <div className="input-container">
                        <input
                          type="email"
                          className="input-txt11111"
                          placeholder="Email ID"
                          value={guestEmail}
                          onChange={handleEmailChange}
                        />
                        <label className="floating-label">Email ID*</label>
                      </div>
                      {!isValid && (
                        <p style={{ color: "red" }}>
                          Please enter a valid email address.
                        </p>
                      )}
                      {/* Message Field */}
                      <div className="input-container">
                        <input
                          type="text"
                          className="input-txt11111"
                          placeholder="Message"
                          value={guestMessage}
                          onInput={(e) => {
                            const value = e.target.value
                              .replace(/^\s+/, "") // Trim leading spaces
                              .replace(/[^A-Za-z0-9\s,]/g, ""); // Allow letters, numbers, spaces, and commas
                            e.target.value = value;
                          }}
                          onChange={(e) => setGuestMessage(e.target.value)}
                        />
                        <label className="floating-label">Message</label>
                      </div>

                      {/* Submit Button */}
                      {/* <button className={`form-btn111 ${(guestName.length > 2 && guestEmail.length > 3 && guestNumber.length > 8) ? 'disabled' : ''}`}  
                     onClick={() => sava_booking_data()}>
                        Book Now
                      </button> */}
                      <button
                        className={`form-btn111 ${guestName.length > 2 &&
                            guestEmail.length > 3 &&
                            guestNumber.length > 8
                            ? ""
                            : "disabled"
                          }`}
                        onClick={() => sava_booking_data()}
                        disabled={
                          !(
                            guestName.length > 2 &&
                            guestEmail.length > 3 &&
                            guestNumber.length > 8
                          )
                        }
                      >
                        Book Now
                      </button>
                    </div>
                  </div>
                )}
              </div>
              <div className="image_design_bottom">
                {event_details && event_details.length > 0 && (
                  <img
                    fetchPriority="high"
                    loading="lazy"
                    width="100%"
                    src={imagelink + event_details[0].event_image}
                    alt="The Best Restaurant in Sweden, Barley's Food Factory, hand-crafted microbreweries, gourmet burger restaurants in Sweden, microbreweries, burger restaurant, Swedish cuisine"
                    className="img_discount1"
                  />
                )}
                <div className="image_design_bottom1">
                  {maxDiscountData && maxDiscountData.length > 0 && (
                    <>
                      <h3 className="bottom_mark1">Deal of the Day</h3>
                      <div className="DiscountDiv">
                        <img
                          src={DiscountImg}
                          alt="Discount Offer"
                          className="discountImage"
                        />
                        <div className="DiscountContent">
                          <h2 className="discountPercentage">
                            {maxDiscountData[0].per_discount}% off
                          </h2>
                          <div className="timeRange">
                            <p className="time">
                              {maxDiscountData[0].start_time}
                            </p>
                            <p className="to">to</p>
                            <p className="time">
                              {
                                maxDiscountData[maxDiscountData.length - 1]
                                  .start_time
                              }
                            </p>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="last_page_design">
              <p className="last-page-Boking">Booking Confirmed</p>
              <img src={gif_save} />

              <p className="last-page-Boking">Booking Details</p>
              <div className="booking-details-container">
                <div className="booking-detail-row">
                  <span className="detail-label">Date:</span>
                  <span className="detail-value">{selectedDate}</span>
                </div>
                <div className="booking-detail-row">
                  <span className="detail-label">Time:</span>
                  <span className="detail-value">
                    {selectedTime}
                    {parseInt(selectedTimeDiscount) !== 0 && (
                      <span className="discount-tag">
                        ({selectedTimeDiscount}% Discount)
                      </span>
                    )}
                  </span>
                </div>
                <div className="booking-detail-row">
                  <span className="detail-label">Guests:</span>
                  <span className="detail-value">
                    {selectedGuest} Person | {selectedChild} Child |{" "}
                    {selectedpet} Pet
                  </span>
                </div>
                <div className="booking-detail-row">
                  <span className="detail-label">Name:</span>
                  <span className="detail-value">{guestName}</span>
                </div>
                <div className="booking-detail-row">
                  <span className="detail-label">Mobile:</span>
                  <span className="detail-value">{guestNumber}</span>
                </div>
                <div className="booking-detail-row">
                  <span className="detail-label">Email:</span>
                  <span className="detail-value">{guestEmail}</span>
                </div>
                {guestMessage && (
                  <div className="booking-detail-row">
                    <span className="detail-label">Message:</span>
                    <span className="detail-value">{guestMessage}</span>
                  </div>
                )}
              </div>
              <p className="reservationText">
                Your Reservation is now Secured.
              </p>
              <p className="reservationText">Thank you for Choosing us</p>
            </div>
          )}
          <h6 className="bottom_mark">Powered by DFoodo Pvt. Ltd.</h6>
        </div>
      )}
    </>
  );
};

export default BookTableModal;
